"use client";

import React, { createContext } from "react";
import { ItemType, MenuItemType } from "antd/es/menu/interface";

export interface BannerItemType {
  component: React.ReactNode;
  height: number;
}

export interface CommonLayoutMenuItemType extends MenuItemType {
  badgeCount?: number;
}

export interface CommonLayoutContextProps {
  collapsed: boolean;
  setCollapsed: (collapsed: boolean) => void;
  sideBarWidth: number;
  leftAddons?: React.ReactNode[];
  rightAddons?: React.ReactNode[];
  mobileLeftAddons?: React.ReactNode;
  mobileRightAddons?: React.ReactNode;
  smallLogoUrl: string;
  largeLogoUrl: string;
  upperMenuItems?: ItemType<CommonLayoutMenuItemType>[];
  upperMenuItemsWithBadges?: ItemType<CommonLayoutMenuItemType>[];
  lowerMenuItems?: ItemType[];
  selectedMenuKeys?: string[];
  openKeys?: string[];
  avatarComponent?: React.ReactNode;
  banners?: BannerItemType[];
  maxWidth?: number;
  isMobile: boolean;
  showSideBar: boolean;
}

const CommonLayoutContext = createContext<CommonLayoutContextProps>({
  collapsed: false,
  setCollapsed: () => {},
  sideBarWidth: 200,
  smallLogoUrl: "",
  largeLogoUrl: "",
  isMobile: false,
  showSideBar: false,
});

export default CommonLayoutContext;
