const config = {
  NEXT_PUBLIC_IDENTITY_SERVER: "https://staging.identity.koble.mx",
  NEXT_PUBLIC_GRAPHQL: "https://staging.graphql.koble.mx/graphql",
  NEXT_PUBLIC_API: "https://staging.api.koble.mx",
  NEXT_PUBLIC_WS: "wss://staging.ws.koble.mx",
  NEXT_PUBLIC_SSO_FRONTEND: "https://staging.sso.koble.mx",
  NEXT_PUBLIC_USER_STUDENT_FRONTEND: "https://staging.student.koble.mx",
  NEXT_PUBLIC_USER_RECRUITER_FRONTEND: "https://staging.recruiter.koble.mx",
  NEXT_PUBLIC_GOOGLE_MAPS_API_KEY: "AIzaSyCFMGQw8H_NbaWlpWyQ4MSgLyDV5EdS_20",
  MIX_PANEL_TOKEN: null,
  FACEBOOK_PIXEL_CODE: 7544537748965734,
};

export default config;
